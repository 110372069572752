/* eslint-disable no-undef */
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import config from '../../util/config'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    authRequest: ['data'],
    authSuccess: ['authUser', 'token'],
    authFailure: null,

    authLogoutRequest: ['data'],
    authLogoutSuccess: null,
    authLogoutFailure: null
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null, 
    error: null,

    token: localStorage.getItem(config.TokenKey),
    initURL: '',
    authUser: JSON.parse(localStorage.getItem('user'))
})

/* ------------- Selectors ------------- */

export const AuthSelectors = {
    getData: state => state.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { authUser, token } = action
    return state.merge({ fetching: false, error: null, authUser, token })
}

export const logoutsuccess = (state) => {
    return state.merge({ ...INITIAL_STATE, 
        token: null,
        authUser: null,
        initURL: ''
    })
    
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.AUTH_REQUEST]: request,
    [Types.AUTH_SUCCESS]: success,
    [Types.AUTH_FAILURE]: failure,

    [Types.AUTH_LOGOUT_REQUEST]: request,
    [Types.AUTH_LOGOUT_SUCCESS]: logoutsuccess,
    [Types.AUTH_LOGOUT_FAILURE]: failure,
    

})
