import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Drawer, Layout } from "antd";

import SidebarContent from "./SidebarContent";
import { toggleCollapsedSideNav, updateWindowWidth } from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import './styles.scss';
import { useLocation, useRouteMatch } from "react-router-dom";
import "./styles.scss";

const { Sider } = Layout;

export const Sidebar = (props) => {

  const path = useLocation();

  const onToggleCollapsedNav = () => {
    props.toggleCollapsedSideNav(!props.navCollapsed);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      props.updateWindowWidth(window.innerWidth)
    });
  }, []);

  const { themeType, navCollapsed, width, navStyle } = props;

  let drawerStyle = "gx-collapsed-sidebar";

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = "";
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = "gx-custom-sidebar"
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar";
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = "gx-collapsed-sidebar"
  }
  if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
    || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
    drawerStyle = "gx-collapsed-sidebar"
  }
  return (
    <div>
      {/* {JSON.stringify(path)} */}
      {/* {path.pathname === '/dashboard' ? null : */}
        <Sider
          className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
          trigger={null}
          collapsed={(width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
          theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
          collapsible
          style={{ background: '#f2f2f2', overflow: 'none', height: '100%' }}>
          {
            navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ?
              <Drawer
                // wrapClassName={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
                placement="left"
                closable={false}
                onClose={onToggleCollapsedNav.bind(this)}
                visible={navCollapsed}>
                <SidebarContent openKeys={props.openKeys}/>
              </Drawer> :
              <SidebarContent openKeys={props.openKeys}/>
          }
        </Sider>
      {/* } */}
    </div>
  )
}

const mapStateToProps = ({ settings, common }) => {
  const { themeType, navStyle, locale } = settings;
  const { navCollapsed, width } = common;
  return { themeType, navStyle, navCollapsed, width, locale }
};
export default connect(mapStateToProps, { toggleCollapsedSideNav, updateWindowWidth })(Sidebar);
