/* eslint-disable no-undef */
import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Popover } from 'antd';
// import { userSignOut } from 'appRedux/actions/Auth';
import './UserProfileHeader.scss';
import useStyles from './UserProfileHeaderStyles';
import AuthActions from '../../appRedux/reducers/AuthRedux'

const UserProfileHeader = (props) => {

    const classes = useStyles();
    const responsiveImg = window.innerWidth < 600 ? 'mobile' : 'web';
    const user = JSON.parse(localStorage.getItem('user'))
    const dispatch = useDispatch();
    // render() {
    // const { authUser } = this.props;
    //console.log('user', user)
    const userMenuOptions = (
        <ul className="gx-user-popover">
            {/* <li>My Account</li> */}
            {/* <li onClick={() => this.props.userSignOut()}>Logoutzx */}
            <li onClick={() => dispatch(AuthActions.authLogoutRequest({ m_user_id: user.m_user_id }))}>
                Logout
            </li>
        </ul>
    );

    return (

        <div>
            <Popover placement="bottomRight" content={userMenuOptions} trigger="click" className={classes.containerProfileHeader}>
                {/* <div>
                    <Avatar src='https://via.placeholder.com/150x150'
                        className="gx-size-40 gx-pointer gx-mr-3" alt="" />
                </div> */}
                <div className="col-profile-header">
                    <p>Welcome,</p>
                    <span className="gx-avatar-name" style={{ color: 'white' }}>{user ? user.name : 'Loading'}</span>
                </div>
                <i className="icon icon-chevron-down gx-fs-xs gx-ml-3" style={{ color: 'white' }} />
            </Popover>
        </div>

    )

    // }
}

// const mapStateToProps = ({ auth }) => {
//     const { authUser } = auth;
//     return { authUser }
// };

export default UserProfileHeader// connect(mapStateToProps, { userSignOut })(UserProfileHeader);
