import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link, useRouteMatch } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { SolutionOutlined, BarChartOutlined, UserOutlined, DashboardOutlined, SettingOutlined, MessageOutlined, FileSearchOutlined } from '@ant-design/icons';

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import "./styles.scss";

const SidebarContent = (props) => {

  const { path, url } = useRouteMatch();
  const { SubMenu } = Menu;
  const roleStaff = (JSON.parse(localStorage.getItem('user'))).role;
  // //console.log('123', props.navCollapsed)

  const [openKeys, setOpenKeys] = React.useState(['Dashboard']);
  const rootSubmenuKeys = ['Dashboard', 'EmployeeInformation', 'KPI', 'RCA', 'Feedback', 'Evaluation', 'Setting'];

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    if (props.openKeys.match(/^\/management-kpi$/) || props.openKeys.match(/\/management-kpi\/joblist/))
      setOpenKeys(["Dashboard"])
    else if (props.openKeys.match(/\/management-kpi\//))
      setOpenKeys(["KPI"])
    else if (props.openKeys.match(/\/informasi-karyawan/))
      setOpenKeys(["EmployeeInformation"])
    else if (props.openKeys.match(/\/rca/))
      setOpenKeys(["RCA"])
    else if (props.openKeys.match(/\/feedback/))
      setOpenKeys(["Feedback"])
    else if (props.openKeys.match(/\/evaluation/))
      setOpenKeys(["Evaluation"])
    else if (props.openKeys.match(/\/setting/))
      setOpenKeys(["Setting"])
    ;      
  }, [props.openKeys]);


  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const { themeType, navStyle, pathname, navCollapsed } = props;
  // const selectedKeys = pathname.substr(1);
  // const defaultOpenKeys = selectedKeys.split('/')[1];
  //console.log('1234', navStyle)
  return (
    <Auxiliary>
      <SidebarLogo />
      <div className="gx-sidebar-content" style={{ height: '100%' }}>
        {/* <UserProfile/> */}
        {/* {!props.navCollapsed &&
          <div className={`gx-sidebar-notifications gx-fs-xxs gx-ml-2 ${getNoHeaderClass(navStyle)}`}>
              <AppsNavigation />
          </div>
        } */}
        <CustomScrollbars className="gx-layout-sider-scrollbar" style={{ height: '100%' }}>
          <Menu
            // defaultOpenKeys={[defaultOpenKeys]}
            // selectedKeys={[selectedKeys]}
            openKeys={openKeys} 
            onOpenChange={onOpenChange}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <SubMenu key="Dashboard" icon={<BarChartOutlined />} title="Dashboard & Job List">
              <Menu.Item key="menu_employee_kpi_dashboard" style={{ background: '#f2f2f2' }}>
                <Link to="/management-kpi" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Dashboard</Link>
              </Menu.Item>

              <Menu.Item key="job_list_summary" style={{ background: '#f2f2f2' }}>
                <Link to="/management-kpi/joblist" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Job List Summary</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="EmployeeInformation" icon={<UserOutlined />} title="Employee Information" style={{}}>
              <Menu.Item key="menu_daftar_karyawan" style={{ background: '#f2f2f2' }}>
                <Link to="/informasi-karyawan/daftar-karyawan" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Employee List</Link>
              </Menu.Item>
              {/* <Menu.Item key="menu_detail_karyawan" style={{ background: '#f2f2f2' }}>
                <Link to="/informasi-karyawan/detail-karyawan" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Employee Details</Link>
              </Menu.Item> */}
              {/* <Menu.Item key="menu_permintaan_perubahan" style={{ background: '#f2f2f2' }}>
                <Link to="/informasi-karyawan/permintaan-perubahan" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Change Request</Link>
              </Menu.Item> */}
            </SubMenu>

            <SubMenu key="KPI" icon={<DashboardOutlined />} title="KPI Management">
              <Menu.ItemGroup key="g1" title="KPI & Achievement">
                <Menu.Item key="menu_input_target" style={{ background: '#f2f2f2' }}>
                  <Link to="/management-kpi/setting-kpi" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    KPI Target</Link>
                </Menu.Item>

                <Menu.Item key="menu_monthly_achievement" style={{ background: '#f2f2f2' }}>
                  <Link to="/management-kpi/monthly-achievement-kpi" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Monthly</Link>
                </Menu.Item>

                <Menu.Item key="menu_kpi_half_achievement" style={{ background: '#f2f2f2' }}>
                  <Link to="/management-kpi/half-of-year-kpi" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Half of Year</Link>
                </Menu.Item>

                <Menu.Item key="menu_kpi_end_achievement" style={{ background: '#f2f2f2' }}>
                  <Link to="/management-kpi/end-of-year-kpi" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    End of Year</Link>
                </Menu.Item>
              </Menu.ItemGroup>

              {roleStaff === 2 ? (
                <Menu.ItemGroup title="Behaviours Staff">
                  <Menu.Item key="menu_behavior_staff_half" style={{ background: '#f2f2f2' }}>
                    <Link to="/management-kpi/behaviors-staff/half-of-year" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                      Half of Year</Link>
                  </Menu.Item>
                  <Menu.Item key="menu_behavior_staff_final" style={{ background: '#f2f2f2' }}>
                    <Link to="/management-kpi/behaviors-staff/final" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                      End of Year</Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              ) : (
                <Menu.ItemGroup title="Behaviours">
                  <Menu.Item key="menu_behavior_manager_half" style={{ background: '#f2f2f2' }}>
                    <Link to="/management-kpi/behaviors-spv-manager/half-of-year" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                      Half of Year</Link>
                  </Menu.Item>
                  <Menu.Item key="menu_behavior_manager_end" style={{ background: '#f2f2f2' }}>
                    <Link to="/management-kpi/behaviors-spv-manager/final" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                      End of Year</Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              )}



              <Menu.ItemGroup title="Development Plan">
                <Menu.Item key="menu_development_half" style={{ background: '#f2f2f2' }}>
                  <Link to="/management-kpi/development-plan/1" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Half of Year</Link>
                </Menu.Item>

                <Menu.Item key="menu_development_end" style={{ background: '#f2f2f2' }}>
                  <Link to="/management-kpi/development-plan/2" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    End of Year</Link>
                </Menu.Item>

              </Menu.ItemGroup>

              {/* <Menu.ItemGroup title="Evaluation">
                <Menu.Item key="menu_summary_half" style={{ background: '#f2f2f2' }}>
                  <Link to="/management-kpi/evaluation/half-of-year" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Summary - Half of Year</Link>
                </Menu.Item>

                <Menu.Item key="menu_summary_end" style={{ background: '#f2f2f2' }}>
                  <Link to="/management-kpi/evaluation/end-of-year" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Summary - End of Year</Link>
                </Menu.Item>
              </Menu.ItemGroup> */}

            </SubMenu>

            <SubMenu key="RCA" icon={<SolutionOutlined />} title="Root Cause Analysis">
            <Menu.Item key="rca" style={{ background: '#f2f2f2' }}>
                <Link to="/rca/list" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  RCA Summary</Link>
              </Menu.Item>
              {/* <Menu.Item key="rca_job_list_summary" style={{ background: '#f2f2f2' }}>
                <Link to="/rca/joblist" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Job List Summary</Link>
              </Menu.Item> */}

            </SubMenu>

            <SubMenu key="Feedback" icon={<MessageOutlined />} title="Stakeholder Feedback" style={{}}>

              {/* <Menu.Item key="menu_feedback_joblist_summary" style={{ background: '#f2f2f2' }}>
                <Link to="/feedback/joblist-summary" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Job List Summary</Link>
              </Menu.Item> */}

              {roleStaff ===3 ? ( 
              <Menu.ItemGroup title="Peers">
                <Menu.Item key="menu_feedback_peers_summary" style={{ background: '#f2f2f2' }}>
                  <Link to="/feedback/peers/summary" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Summary</Link>
                </Menu.Item>
              </Menu.ItemGroup>
              ):(
                <Menu.ItemGroup title="Peers">
                <Menu.Item key="menu_feedback_peers_half" style={{ background: '#f2f2f2' }}>
                 <Link to="/feedback/peers/half-year" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                   Half of Year</Link>
               </Menu.Item>

               <Menu.Item key="menu_feedback_peers_final" style={{ background: '#f2f2f2' }}>
                 <Link to="/feedback/peers/final" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                   End of Year</Link>
               </Menu.Item> 

               <Menu.Item key="menu_feedback_peers_summary" style={{ background: '#f2f2f2' }}>
                 <Link to="/feedback/peers/summary" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                   Summary</Link>
               </Menu.Item>
             </Menu.ItemGroup>
              )}

              {roleStaff ===3 ? (
              <Menu.ItemGroup title="Superior/Subordinate">
                <Menu.Item key="menu_feedback_summary_bawahan" style={{ background: '#f2f2f2' }}>
                  <Link to="/feedback/bawahan/summary" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Summary</Link>
                </Menu.Item>
              </Menu.ItemGroup>
              ):(
              <Menu.ItemGroup title="Superior/Subordinate">
                <Menu.Item key="menu_feedback_bawahan_half" style={{ background: '#f2f2f2' }}>
                  <Link to="/feedback/bawahan/half-year" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Half of Year</Link>
                </Menu.Item>
                <Menu.Item key="menu_feedback_bawahan_final" style={{ background: '#f2f2f2' }}>
                  <Link to="/feedback/bawahan/final" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    End of Year</Link>
                </Menu.Item>
                <Menu.Item key="menu_feedback_summary_bawahan" style={{ background: '#f2f2f2' }}>
                  <Link to="/feedback/bawahan/summary" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Summary</Link>
                </Menu.Item>
              </Menu.ItemGroup>
              )}

              {/* <Menu.ItemGroup  title="Joblist Summary">
                <Menu.Item key="menu_joblist_summary_final" style={{ background: '#f2f2f2' }}>
                  <Link to="/feedback/summary-final" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Final</Link>
                </Menu.Item>
                <Menu.Item key="menu_feedback_bawahan_half" style={{ background: '#f2f2f2' }}>
                  <Link to="/feedback/bawahan/half-year" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                    Half Year</Link>
                </Menu.Item>
              </Menu.ItemGroup> */}
            </SubMenu>

            <SubMenu key="Evaluation" icon={<FileSearchOutlined />} title="Evaluation">
              <Menu.Item key="menu_evaluation_half_year" style={{ background: '#f2f2f2' }}>
                <Link to="/evaluation/half-year" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Half of Year</Link>
              </Menu.Item>
              <Menu.Item key="menu_evaluation_final" style={{ background: '#f2f2f2' }}>
                <Link to="/evaluation/final" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  End of Year</Link>
              </Menu.Item>
            </SubMenu>
            
            {roleStaff ===3 && (
            <SubMenu key="Setting" icon={<SettingOutlined />} title="Setting">
              {/* <Menu.Item key="menu_setting_user" style={{ background: '#f2f2f2' }}>
                <Link to="/setting/data-user" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Data User</Link>
              </Menu.Item> */}

              {/* <Menu.Item key="menu_setting_periode_penilaian" style={{ background: '#f2f2f2' }}>
                <Link to="/setting/data-periode-penilaian" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Data Periode Penilaian</Link>
              </Menu.Item> */}

              <Menu.Item key="menu_setting_siklus_pelaporan" style={{ background: '#f2f2f2' }}>
                <Link to="/setting/data-siklus-pelaporan" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Data Siklus Pelaporan</Link>
              </Menu.Item>

              <Menu.Item key="menu_setting_unit_pengukuran" style={{ background: '#f2f2f2' }}>
                <Link to="/setting/data-unit-pengukuran" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                  Data Unit Pengukuran</Link>
              </Menu.Item>
            </SubMenu>
            )}
            {/* <Menu.Item key="dashboard" style={{ background: '#f2f2f2' }}>
              <Link to="/management-kpi" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                Employee KPI Dashboard</Link>
            </Menu.Item> */}

            {/* <Menu.Item key="dashboard" style={{ background: '#f2f2f2' }}>
              <Link to="/dashboard" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                Dashboard</Link>
            </Menu.Item>

            <Menu.Item key="folder1" style={{ background: '#f2f2f2' }}>
              <Link to="/profile" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                Create Petugas</Link>
            </Menu.Item>

            <Menu.Item key="list_petugas" style={{ background: '#f2f2f2' }}>
              <Link to="/list_petugas" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                List Petugas</Link>
            </Menu.Item>

            <Menu.Item key="folder3" style={{ background: '#f2f2f2' }}>
              <Link to="/folder3" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                List Pasien</Link>
            </Menu.Item>

            <Menu.Item key="folder3" style={{ background: '#f2f2f2' }}>
              <Link to="/folder3" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                Informasi Karyawan</Link>
            </Menu.Item>

            <Menu.Item key="folder3" style={{ background: '#f2f2f2' }}>
              <Link to="/folder3" style={{ color: 'black' }}><i className="icon icon-widgets" style={{ marginRight: 27.5 }} />
                Manegement Kinerja KPI</Link>
            </Menu.Item> */}

          </Menu>
        </CustomScrollbars>
      </div>
    </Auxiliary>
  );
}


SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, common }) => {
  const { navStyle, themeType, locale, navCollapsed } = settings;
  const { pathname } = common;
  return { navStyle, themeType, locale, pathname, navCollapsed }
};
export default connect(mapStateToProps)(SidebarContent);

