// const TARGET = process.env.REACT_APP_API_URL || 'localhost';
// const PORT = process.env.REACT_APP_API_PORT || 1337;
// const HTTP = process.env.REACT_APP_API_HTTP || 'http';
// const URL = 'http://localhost:5000/'

const URL = window.location.protocol + '//api-pms.digitalsistem.co.id/'
const TokenKey = 'ApiPMSKey';

module.exports = {
    footerText: 'PMS V.1.0.0 © PT. Digital Sistem Indonesia',
    URL,
    TokenKey
}