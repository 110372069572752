import React from 'react';
import {useDispatch} from 'react-redux';
import {Avatar, Popover} from 'antd';
// import {userSignOut} from "appRedux/actions/Auth";
import AuthActions from '../../appRedux/reducers/AuthRedux'

const UserInfo = () => {
    // eslint-disable-next-line no-undef
    const user = JSON.parse(localStorage.getItem('user'))

    const dispatch = useDispatch();

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li>My Account</li>
            <li>Connections</li>
            <li onClick={() => dispatch(AuthActions.authLogoutRequest({ m_user_id: user.m_user_id }))}>Logout
            </li>
        </ul>
    );

    return (
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
            trigger="click">
            <Avatar src={'https://via.placeholder.com/150x150'}
                className="gx-avatar gx-pointer" alt=""/>
        </Popover>
    )

}

export default UserInfo;
