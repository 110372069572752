/* eslint-disable no-undef */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This auth uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import AuthActions from '../appRedux/reducers/AuthRedux'
// import {useDispatch} from 'react-redux';
import { fetchStart, fetchSuccess, fetchError } from '../appRedux/actions/Common'
import config from '../util/config'
// import { AuthSelectors } from '../Redux/AuthRedux'


// const cookieOpt = { 
//     path: '/' ,
//     domain: '.enesis.com', // (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
//     secure: false, // (boolean): Is only accessible through HTTPS? 
//     sameSite: false
// }

export function* authRequest(api, action) {
    const { data } = action

    yield put(fetchStart())

    // make the call to the api
    const response = yield call(api.authRequest, data)
    //console.log('JSON login', response)
    // success?
    if (response.ok) {
        const responseData = response.data

        // let result = []
        // if(responseData.length > 0){
        //     result = responseData.filter(item=>item.username === data.username && item.password === data.password)
        // }
        //! logic roles

        // if(result.length > 0){
        // let token = "ApiPMSKey"
        localStorage.setItem(config.TokenKey, responseData.token);
        localStorage.setItem('user', JSON.stringify(responseData)); //localStorage.setItem('user', JSON.stringify(result)); //userData
        yield put(AuthActions.authSuccess(responseData, responseData.token))
        yield put(fetchSuccess())
        // }  

        // } else {
        //     yield put(AuthActions.authFailure())
        //     yield put(fetchError('Field Token tidak ditemukan'))
        // }
    } else {
        yield put(AuthActions.authFailure())
        yield put(fetchError('Error Login'))
    }
}

export function* authLogoutRequest(api, action) {
    // success?
    yield put(fetchStart())

    // if (responseData && responseData.status === 200) {
    //console.log('masuk sini')
    localStorage.removeItem(config.TokenKey);
    localStorage.removeItem('user');
    yield put(AuthActions.authLogoutSuccess())

    yield put(fetchSuccess())
    // } else {
    //     yield put(AuthActions.authLogoutFailure())
    //     yield put(fetchError(response.data.error))
    // }
}