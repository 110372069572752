import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input, Typography, Image, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// import {userSignIn} from '../appRedux/actions/Auth';
import AuthActions from '../appRedux/reducers/AuthRedux';

import IntlMessages from 'util/IntlMessages';
import InfoView from 'components/InfoView';
import useStyles from './styles';
import './styles.scss';

const SignIn = (props) => {

    const { Title, Text } = Typography;

    const classes = useStyles();
    const dispatch = useDispatch();
    const token = useSelector(({ auth }) => auth.token);
    const authError = useSelector(({ auth }) => auth.error);
    // const auth = useSelector(({auth}) => auth);

    const onFinishFailed = errorInfo => {
        //console.log('Failed:', errorInfo);
    };

    const onFinish = values => {
        const param = {
            username: values.username,
            password: values.password,
        }
        dispatch(AuthActions.authRequest(param))
        // dispatch(userSignIn(values));
    };

    useEffect(() => {
        if (token !== null) {
            props.history.push('/');
        }
    }, [token, props.history]);

    const responsiveImg = window.innerWidth < 600 ? 'mobile' : 'web';

    return (
        <div className='container-page-login'>
            <Row type='flex' justify='space-between' align='top' style={{ width: responsiveImg === 'mobile' ? '100vw' : '50vw' }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ marginBottom: responsiveImg === 'mobile' ? 20 : 0 }}>
                    <Row type='flex' justify='center' align='middle'>
                        <Col xs={20} sm={20} md={24} lg={24} xl={24}>
                            <Title level={responsiveImg === 'mobile' ? 2 : 1} className={classes.titleTable}>PERFORMANCE MANAGEMENT</Title>
                        </Col>
                    </Row>
                    <Row type='flex' justify='center'>
                        <Col xs={10} sm={10} md={12} lg={12} xl={12}>
                            <Row type='flex' justify='center' align='middle'>
                                <Image
                                    width={responsiveImg === 'mobile' ? 150 : 200}
                                    preview={false}
                                    src={require('assets/images/pms/logo-login.png')}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type='flex' justify='center'>
                        <Col span={20}>
                            <Title level={4}>Sign In</Title>
                        </Col>
                    </Row>
                    <Row type='flex' justify='center' className={classes.subTitleSignin}>
                        <Col span={20}>
                            <Text>Welcome to PMS! Please sign in to continue!</Text>
                        </Col>
                    </Row>
                    {authError && (
                    <Row type='flex' justify='center' className={classes.subTitleSignin}>
                        <Col span={20}>
                            <Text style={{color:'red'}}>Authentication Failed!. Please make sure your Username and Passwod are correct!</Text>
                        </Col>
                    </Row>
                    )}
                    <Row type='flex' justify='center'>
                        <Col span={20}>
                            <div className='container-form-login'>
                                <Form
                                    initialValues={{ remember: true }}
                                    name='basic'
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    className='gx-signin-form gx-form-row0'
                                    layout='vertical' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                >
                                    <Form.Item rules={[{ required: true, message: 'Please input your Username!' }]} name='username' label='Username'>
                                        <Input width='100%' />
                                    </Form.Item>
                                    <Form.Item rules={[{ required: true, message: 'Please input your Password!' }]} name='password' label='Password'>
                                        <Input type='password' />
                                    </Form.Item>
                                    <Form.Item>
                                        <div className='btn-submit'>
                                            <Button type='primary' className='gx-mb-0' htmlType='submit'>
                                                <IntlMessages id='app.userAuth.signIn' />
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default SignIn;
