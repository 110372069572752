// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import config from '../util/config'
// import StaticVar from '../Config/StaticVar'

// our "constructor"
const create = (baseURL = config.URL) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            'Cache-Control': 'no-cache',
            // 'Content-Type': 'application/json',
            'x-tag': 'Performance Management System',
            'Authorization': 'Bearer ' + localStorage.getItem(config.TokenKey),
        },
        // 10 second timeout...
        timeout: (10000 /* * 30 */) //di uncoment = 30 menit
    })

    // ------
    // STEP 2
    // ------
    //
    // Define some functions that call the api.  The goal is to provide
    // a thin wrapper of the api layer providing nicer feeling functions
    // rather than "get", "post" and friends.
    //
    // I generally don't like wrapping the output at this level because
    // sometimes specific actions need to be take on `403` or `401`, etc.
    //
    // Since we can't hide from that, we embrace it by getting out of the
    // way at this level.
    // 
    const templateRequest = (data) => api.get('employees', data)
    const authRequest = (data) => api.post('/login', data) //api.get('/data/user', data)   
    const authLogoutRequest = (data) => api.post('Auths/logout', data)
    const petugasRequest = (data, id) => api.get(`Petugaslapangans${(id) ? `/${id}` : ''}`, data)

    //#region master
    const department = () => api.get('/department')
    const departmentAdd = (data) => api.post('/department', data)
    const departmentUpdate = (data, id) => api.put('/department/' + id, data)
    const departmentDelete = (id) => api.delete('/department/' + id)

    const position = () => api.get('/position')
    const positionAdd = (data) => api.post('/position', data)
    const positionUpdate = (data, id) => api.put('/position/' + id, data)
    const positionDelete = (id) => api.delete('/position/' + id)

    const role = () => api.get('/role')
    const roleAdd = (data) => api.post('/role', data)
    const roleUpdate = (data, id) => api.put('/role/' + id, data)
    const roleDelete = (id) => api.delete('/role/' + id)

    const periodePenilaian = () => api.get('/assessmentPeriod')
    const periodePenilaianUpdate = (data, id) => api.put('/assessmentPeriod/' + id, data)

    const siklusPelaporan = () => api.get('/reportCycle')
    const siklusPelaporanAdd = (data) => api.post('/reportCycle', data)
    const siklusPelaporanUpdate = (data, id) => api.put('/reportCycle/' + id, data)
    const siklusPelaporanDelete = (id) => api.delete('/reportCycle/' + id)

    const uom = () => api.get('/unitMeasurement')
    const uomAdd = (data) => api.post('/unitMeasurement', data)
    const uomUpdate = (data, id) => api.put('/unitMeasurement/' + id, data)
    const uomDelete = (id) => api.delete('/unitMeasurement/' + id)
    //#endregion master

    const daftarKaryawan = (data) => api.post('/user/page', data)
    const daftarKaryawanAdd = (data) => api.post('/user', data)
    const daftarKaryawanUpdate = (data, id) => api.put('/user/' + id, data)
    const daftarKaryawanGetAll = () => api.get('/user');
    const daftarKaryawanGetOne = (id) => api.get('/user/' + id);
    const daftarKaryawanGetNIP = (emp_id) => api.get('/user/nip/' + emp_id);
    const daftarKaryawanGetByParent = (id) => api.get('/user/parent/' + id)
    const daftarKaryawanGetByDepartment = (id) => api.get('/user/department/' + id)
    const daftarKaryawanGetPeers = (superiorId, data) => api.post('/user/peers/' + superiorId, data);
    const daftarKaryawanGetSuperior = (superiorId) => api.get('/user/superior/' + superiorId);

    //#region KPI
    const masterKPI = (userId) => api.get('/kpi/user/' + userId)
    const masterKPIAdd = (data) => api.post('/kpi', data)
    const masterKPIEdit = (id, data) => api.put('/kpi/' + id, data)

    const detailMasterKPI = (kpiID) => api.get('/kpi_details/parent/' + kpiID)
    const detailMasterKPIAdd = (data) => api.post('/kpi_details', data)
    const detailMasterKPIEdit = (id, data) => api.put('/kpi_details/' + id, data)

    const userKPIGet = (data) => api.post('/userKpi/data', data)
    const userKPISubmit = (data) => api.post('/userKpi/submitKPI', data)
    const userKPIStatus = (data) => api.post('/userKpi/status', data)
    const userKPIJob = (data) => api.post('/userKpi/jobList', data)

    const userMonthlyKPIMasterGet = (data) => api.post('/userKpiMonthly/master', data)
    const userMonthlyKPIMasterGetAllMonths = (data) => api.post('/userKpiMonthly/masterAllMonths', data)
    const userMonthlyKPIMasterSave = (data) => api.post('/userKpiMonthly', data)
    const userMonthlyKPIMasterStatus = (data) => api.post('/userKpiMonthly/status', data)
    const userMonthlyKPIMasterPeriode = (data) => api.post('/userKpiMonthly/periode', data)
    const userMonthlyKPIMasterComment = (data) => api.post('/userKpiMonthly/comment', data)

    const userBehaviourGetByUserId = (data) => api.post('/userBehaviour/user', data);
    const userBehaviourAdd = (data) => api.post('/userBehaviour', data);
    const userBehaviourUpdateStatus = (data) => api.post('/userBehaviour/status', data);

    const userBehaviourDetailsGetByUserId = (data) => api.post('/userBehaviourDetail/details', data);
    const userBehaviourSubmittedDetailsGetByUserId = (data) => api.post('/userBehaviourDetail/submitted', data);
    const userBehaviourDetailAdd = (data) => api.post('/userBehaviourDetail', data);
    const userBehaviourDetailUpdate = (data, id) => api.put('/userBehaviourDetail/' + id, data);
    // const userBehaviourSubmit = (data, id) => api.post('/userBehaviour/status', data);

    const userDevelopmentGet = (data) => api.post('/userDevelopmentPlan', data);
    const userDevelopmentPlan = (data) => api.post('/userDevelopmentPlan/plan', data);
    const userDevelopmentTarget = (data) => api.post('/userDevelopmentPlan/target', data);
    const userDevelopmentPath = (data) => api.post('/userDevelopmentPlan/path', data);
    const userDevelopmentMobility = (data) => api.post('/userDevelopmentPlan/mobility', data);
    const userDevelopmentStatus = (data) => api.post('/userDevelopmentPlan/status', data);

    const userRCA = (data) => api.post('/rca/page', data);
    const userRCAAdd = (data) => api.post('/rca', data);
    const userRCAUpdate = (id, data) => api.put('/rca/' + id, data);
    const userRCADelete = (id) => api.delete('/rca/' + id);

    const userRCADetail = (data) => api.post('/rcaDetail/page', data);
    const userRCADetailAdd = (data) => api.post('/rcaDetail', data);
    const userRCADetailUpdate = (id, data) => api.put('/rcaDetail/' + id, data);
    const userRCADetailDelete = (id) => api.delete('/rcaDetail/' + id);
    //#endregion KPI

    const userFeedbackAdd = (data) => api.post('/userFeedback', data);
    const userFeedbackGetByUserId = (data) => api.post('/userFeedback/user', data);
    const userFeedbackGetRequestNeedApproval = (data) => api.post('/userFeedback/needApproval', data);
    const userFeedbackUpdateStatus = (userId, data) => api.post('/userFeedback/status/' + userId, data);

    const userFeedbackAddRecipient = (data) => api.post('/userFeedbackRecipient', data);
    const userFeedbackGetRecipient = (data) => api.post('/userFeedbackRecipient/list', data);
    const userFeedbackUserGet = (data) => api.post('/userFeedbackRecipient/user', data);
    const userFeedbackGetRequestList = (data) => api.post('/userFeedbackRecipient/request', data);
    const userFeedbackDeleteRecipient = (id) => api.delete('/userFeedbackRecipient/' + id);
    const userFeedbackUpdateStatusAllRecipient = (feedbackId, data) => api.post('/userFeedbackRecipient/updateAll/' + feedbackId, data);
    const userFeedbackUpdateStatusOneRecipient = (feedbackRecipientId, data) => api.post('/userFeedbackRecipient/updateOne/' + feedbackRecipientId, data);

    const userFeedbackGetDetail = (data) => api.post('/userFeedbackDetail/detail', data);
    const userFeedbackAddDetail = (data) => api.post('/userFeedbackDetail', data);
    const userFeedbackUpdateDetail = (questionId, data) => api.put('/userFeedbackDetail/' + questionId, data);

    const userEvaluationGet = (data) => api.post('/userEvaluation', data)
    const userEvaluationAdd = (data) => api.post('/userEvaluation/create', data);
    const userEvaluationUpdateKomentarAtasan = (userId, data) => api.post('/userEvaluation/updateAtasan/' + userId, data);
    const userEvaluationUpdateKomentarKaryawan = (userId, data) => api.post('/userEvaluation/updateKaryawan/' + userId, data);
    const userEvaluationUpdateKomentarHRD = (userId, data) => api.post('/userEvaluation/updateHRD/' + userId, data);

    // ------
    // STEP 3
    // ------
    //
    // Return back a collection of functions that we would consider our
    // interface.  Most of the time it'll be just the list of all the
    // methods in step 2.
    //
    // Notice we're not returning back the `api` created in step 1?  That's
    // because it is scoped privately.  This is one way to create truly
    // private scoped goodies in JavaScript.
    //
    return {
        // a list of the API functions from step 2 
        templateRequest,
        authRequest,
        authLogoutRequest,
        petugasRequest,

        daftarKaryawan,
        daftarKaryawanGetByParent,
        daftarKaryawanGetByDepartment,

        //#region master
        department,
        departmentAdd,
        departmentUpdate,
        departmentDelete,

        position,
        positionAdd,
        positionUpdate,
        positionDelete,

        role,
        roleAdd,
        roleUpdate,
        roleDelete,

        periodePenilaian,
        periodePenilaianUpdate,

        siklusPelaporan,
        siklusPelaporanAdd,
        siklusPelaporanUpdate,
        siklusPelaporanDelete,

        uom,
        uomAdd,
        uomUpdate,
        uomDelete,
        //#endregion master

        daftarKaryawan,
        daftarKaryawanAdd,
        daftarKaryawanUpdate,
        daftarKaryawanGetAll,
        daftarKaryawanGetOne,
        daftarKaryawanGetNIP,
        daftarKaryawanGetByParent,
        daftarKaryawanGetPeers,
        daftarKaryawanGetSuperior,

        //#region KPI
        masterKPI,
        masterKPIAdd,
        masterKPIEdit,

        detailMasterKPI,
        detailMasterKPIAdd,
        detailMasterKPIEdit,

        userKPIGet,
        userKPISubmit,
        userKPIStatus,
        userKPIJob,

        userMonthlyKPIMasterGet,
        userMonthlyKPIMasterGetAllMonths,
        userMonthlyKPIMasterSave,
        userMonthlyKPIMasterStatus,
        userMonthlyKPIMasterPeriode,
        userMonthlyKPIMasterComment,

        userBehaviourGetByUserId,
        userBehaviourAdd,
        userBehaviourUpdateStatus,

        userBehaviourDetailsGetByUserId,
        userBehaviourSubmittedDetailsGetByUserId,
        userBehaviourDetailAdd,
        userBehaviourDetailUpdate,

        userDevelopmentGet,
        userDevelopmentPlan,
        userDevelopmentTarget,
        userDevelopmentPath,
        userDevelopmentMobility,
        userDevelopmentStatus,

        userRCA,
        userRCAAdd,
        userRCADelete,
        userRCAUpdate,

        userRCADetail,
        userRCADetailAdd,
        userRCADetailUpdate,
        userRCADetailDelete,

        userFeedbackAdd,
        userFeedbackGetByUserId,
        userFeedbackGetRequestNeedApproval,
        userFeedbackUpdateStatus,

        userFeedbackAddRecipient,
        userFeedbackGetRecipient,
        userFeedbackUserGet,
        userFeedbackGetRequestList,
        userFeedbackDeleteRecipient,
        userFeedbackUpdateStatusAllRecipient,
        userFeedbackUpdateStatusOneRecipient,

        userFeedbackGetDetail,
        userFeedbackAddDetail,
        userFeedbackUpdateDetail,

        userEvaluationGet,
        userEvaluationAdd,
        userEvaluationUpdateKomentarAtasan,
        userEvaluationUpdateKomentarKaryawan,
        userEvaluationUpdateKomentarHRD
        //#endregion KPI

    }
}

// let's return back our create method as the default.
export default {
    create
}
