import { createUseStyles } from "react-jss";

const responsiveImg = window.innerWidth < 600 ? 'mobile' : 'web';

export default createUseStyles((theme) => ({
    containerProfileHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#e26f3a !important',
        padding: '0px 20px',
        height: responsiveImg === 'mobile' ? '72px !important' : '72px !important',
    },
}));
