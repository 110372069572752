import { takeLatest, all } from 'redux-saga/effects'
import API from '../services/Api' 

/* ------------- Types ------------- */
 
import { TemplateTypes } from '../appRedux/reducers/TemplateRedux'  
import { AuthTypes } from '../appRedux/reducers/AuthRedux'  
import { PetugasTypes } from '../appRedux/reducers/PetugasRedux'  

/* ------------- Sagas ------------- */
 
import { templateRequest } from './TemplateSagas'
import { authRequest, authLogoutRequest } from './AuthSagas'
import { petugasRequest } from './PetugasSagas'
 
/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = { ...API.create(), }

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {  
    //console.log('ROOT sagas JALAN !')
    yield all([
        takeLatest(TemplateTypes.TEMPLATE_REQUEST, templateRequest, api), 
        takeLatest(AuthTypes.AUTH_REQUEST, authRequest, api), 
        takeLatest(AuthTypes.AUTH_LOGOUT_REQUEST, authLogoutRequest, api), 
        
        // some sagas only receive an action
        // takeLatest(StartupTypes.STARTUP, startup),
        takeLatest(PetugasTypes.PETUGAS_REQUEST, petugasRequest, api), 
        
    ]) 
}
