import { createUseStyles } from "react-jss";

const minWidth = 400;

export default createUseStyles((theme) => ({
    titleTable: {
        textAlign: 'center'
    },
    subTitleSignin: {
        fontSize: 16,
        paddingBottom: 10
    },
    createAccount: {
        textAlign: 'center',
        width: '100%'
    }
}));