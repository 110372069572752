// import React from 'react';
// import {Route, Switch} from 'react-router-dom';

// import asyncComponent from 'util/asyncComponent';

// const App = ({match}) => (
//     <div className="gx-main-content-wrapper">
//         <Switch>
//             <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
//             <Route path={'/test'} component={asyncComponent(() => import('./TestReduxSaga'))} />
//             {/* SAMPLE */}
//             {/* 
//        <Route exact path={'/cmo'} component={asyncComponent(() => import('./CMO/DataCMO'))} />
//        <Route path={'/cmo/dashboard-cmo/detail-cmo/:id'} component={asyncComponent(() => import('./CMO/ViewCMO'))} /> 
//       */}
//         </Switch>
//     </div>
// );

// export default App;


import React, {useRef} from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import { useReactToPrint} from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import {Button} from "antd";
import './styles.scss';

const App = ({ match }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  return (
    <div>
      <Button icon={<PrinterOutlined />} onClick={handlePrint} style={{position:"absolute",zIndex:999,border:"1px solid rgb(232, 232, 232)"}}>Print</Button>
      <div className="gx-main-content-wrapper" style={{padding:"45px 20px 20px 20px"}} ref={componentRef}>
        <Switch>
          {/* DASHBOARD */}
          {/* <Route exact path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} /> */}
          <Route exact path={`${match.url}dashboard`} component={asyncComponent(() => import('./ManagementKPI/DashboardManagementKPI'))} />

          {/* EMPLOYEE */}
          <Route exact path={`${match.url}informasi-karyawan/daftar-karyawan`} component={asyncComponent(() => import('./InformasiKaryawan/DaftarKaryawan'))} />
          <Route exact path={`${match.url}informasi-karyawan/detail-karyawan`} component={asyncComponent(() => import('./InformasiKaryawan/DetailKaryawan'))} />
          <Route exact path={`${match.url}informasi-karyawan/permintaan-perubahan`} component={asyncComponent(() => import('./InformasiKaryawan/PermintaanPerubahan'))} />

          {/* SETTING */}
          <Route exact path={`${match.url}setting/data-user`} component={asyncComponent(() => import('./Setting/User/DataUser'))} />
          <Route exact path={`${match.url}setting/data-periode-penilaian`} component={asyncComponent(() => import('./Setting/PeriodePenilaian/DataPeriodePenilaian'))} />
          <Route exact path={`${match.url}setting/data-siklus-pelaporan`} component={asyncComponent(() => import('./Setting/SirklusPelaporan/DataSirklusPelaporan'))} />
          <Route exact path={`${match.url}setting/data-unit-pengukuran`} component={asyncComponent(() => import('./Setting/UnitPengukuran/DataUnitPengukuran'))} />

          {/* MANAGEMENT KPI */}
          <Route exact path={`${match.url}management-kpi`} component={asyncComponent(() => import('./ManagementKPI/DashboardManagementKPI'))} />
          <Route exact path={`${match.url}management-kpi/setting-kpi`} component={asyncComponent(() => import('./ManagementKPI/SettingKPI/DataSettingKPI'))} />
          <Route exact path={`${match.url}management-kpi/monthly-achievement-kpi`} component={asyncComponent(() => import('./ManagementKPI/MonthlyAchievement/DataMonthlyAchievement'))} />
          <Route exact path={`${match.url}management-kpi/half-of-year-kpi`} component={asyncComponent(() => import('./ManagementKPI/HalfYearKPI/DataHalfYearKPI'))} />
          <Route exact path={`${match.url}management-kpi/end-of-year-kpi`} component={asyncComponent(() => import('./ManagementKPI/EndYearKPI/DataEndYearKPI'))} />

          {/* ROOT CAUSE ANALYTICS */}
          <Route exact path={`${match.url}rca/list`} component={asyncComponent(() => import('./RootCauseAnalysis'))} />
          <Route exact path={`${match.url}rca/detail`} component={asyncComponent(() => import('./RootCauseAnalysis/detail'))} />

          {/* BEHAVIOURS - STAFF */}
          <Route exact path={`${match.url}management-kpi/behaviors-staff/half-of-year`} component={asyncComponent(() => import('./ManagementKPI/BehaviorsStaff/BehavioursHalfYear/DataBehavioursHalfYear'))} />
          <Route exact path={`${match.url}management-kpi/behaviors-staff/final`} component={asyncComponent(() => import('./ManagementKPI/BehaviorsStaff/BehavioursFinal/DataBehavioursFinal'))} />

          {/* BEHAVIOURS - SPV MANAGER */}
          <Route exact path={`${match.url}management-kpi/behaviors-spv-manager/half-of-year`} component={asyncComponent(() => import('./ManagementKPI/BehaviorsSpvManager/BehavioursHalfYear/DataBehavioursHalfYear'))} />
          <Route exact path={`${match.url}management-kpi/behaviors-spv-manager/final`} component={asyncComponent(() => import('./ManagementKPI/BehaviorsSpvManager/BehavioursFinal/DataBehavioursFinal'))} />

          {/* DEVELOPMENT PLAN */}
          <Route exact path={`${match.url}management-kpi/development-plan/:periode`} component={asyncComponent(() => import('./ManagementKPI/DevelopmentPlan/DataDevelopmentPlan'))} />
          <Route exact path={`${match.url}management-kpi/development-plan/:periode`} component={asyncComponent(() => import('./ManagementKPI/DevelopmentPlan/DataDevelopmentPlan'))} />

          {/* EVALUATION */}
          <Route exact path={`${match.url}evaluation/half-year`} component={asyncComponent(() => import('./Evaluation/Half Year'))} />
          <Route exact path={`${match.url}evaluation/final`} component={asyncComponent(() => import('./Evaluation/Final'))} />

          {/* FEEDBACK */}
          <Route exact path={`${match.url}feedback/peers/final`} component={asyncComponent(() => import('./Feedbacks/FeedbackPeers/FeedbackFinal/DataFeedbackFinal'))} />
          <Route exact path={`${match.url}feedback/peers/half-year`} component={asyncComponent(() => import('./Feedbacks/FeedbackPeers/FeedbackHalfYear/DataFeedbackHalfYear'))} />
          <Route exact path={`${match.url}feedback/peers/summary`} component={asyncComponent(() => import('./Feedbacks/FeedbackPeers/SummaryFeedback/DataSummaryFeedback'))} />
          <Route exact path={`${match.url}feedback/bawahan/final`} component={asyncComponent(() => import('./Feedbacks/FeedbackBawahan/FeedbackFinal/DataFeedbackFinal'))} />
          <Route exact path={`${match.url}feedback/bawahan/half-year`} component={asyncComponent(() => import('./Feedbacks/FeedbackBawahan/FeedbackHalfYear/DataFeedbackHalfYear'))} />      
          <Route exact path={`${match.url}feedback/bawahan/summary`} component={asyncComponent(() => import('./Feedbacks/FeedbackBawahan/SummaryFeedback/DataSummaryFeedback'))} />
          <Route exact path={`${match.url}feedback/joblist-summary`} component={asyncComponent(() => import('./Feedbacks/JobListSummary'))} />

          {/* JOB LIST SUMMARY */}
          <Route exact path={`${match.url}management-kpi/joblist`} component={asyncComponent(() => import('./ManagementKPI/JobListSummary'))} />

          <Route exact path={`${match.url}profile`} component={asyncComponent(() => import('./Profile'))} />
          <Route exact path={`${match.url}folder2`} component={asyncComponent(() => import('./Folder2'))} />
          <Route exact path={`${match.url}folder3`} component={asyncComponent(() => import('./Folder3'))} />
          <Route exact path={`${match.url}list_petugas`} component={asyncComponent(() => import('./Petugas/ListPetugas'))} />
        </Switch>
      </div>
    </div>
  )
};

export default App;
